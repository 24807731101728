// extracted by mini-css-extract-plugin
export var articleAuthor = "IndexTpl-module--articleAuthor--lRAYy";
export var articleItem = "IndexTpl-module--articleItem--uJa6o";
export var bannerC = "IndexTpl-module--bannerC--e5XVW";
export var footerC = "IndexTpl-module--footerC--bR5ov";
export var gallery = "IndexTpl-module--gallery--PvWV-";
export var heading1 = "IndexTpl-module--heading1--9cbLw";
export var heading2 = "IndexTpl-module--heading2--rZ2ug";
export var root = "IndexTpl-module--root--Uo5nN";
export var videoWrapper = "IndexTpl-module--videoWrapper--rpNsr";
export var wrapperC = "IndexTpl-module--wrapperC--TqiCY";