import Layout2 from 'components/Layout2';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import AtomaticIndex from 'templates/Atomatic/AtomaticIndexTpl';

const Tpl = props => (
  <Layout2 location={props.location}>
    <AtomaticIndex {...props} />
  </Layout2>
);
const Index = props => (
  <StaticQuery
    query={graphql`
      query AtomaticQuery {
        landingPage: atomaticJson {
          title
          meta {
            description
          }
          banner {
            heading
            subheading
            text1
            text2
            videoUrl
          }
          articles {
            title
            linkTo
            author
          }
          pictures {
            publicURL
          }
        }
      }
    `}
    render={data => <Tpl data={data} {...props} />}
  />
);
Tpl.propTypes = {
  location: PropTypes.object,
};

export default Index;
