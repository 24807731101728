import { Heading, P, Wrapper } from 'components/base';
import Metadata from 'components/Metadata';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';
import { articleAuthor, articleItem, articlesC, bannerC, footerC, gallery, heading1, heading2, root, videoWrapper, wrapperC } from './IndexTpl.module.scss';

const IndexTpl = ({ data }) => {
  const {
    landingPage: {
      title,
      meta: { description },
      banner,
      articles,
      pictures,
    },
  } = data;
  return (
    <div className={root}>
      <Metadata title={title} description={description} />
      <Wrapper size="M" className={wrapperC}>
        <h1 className={heading1}>{banner.heading}</h1>
        <h1 className={heading2}>{banner.subheading}</h1>
        <div className={bannerC}>
          <P size="L">{banner.text1}</P>
          <P size="L">{banner.text2}</P>
        </div>
        <div className={videoWrapper}>
          <ReactPlayer width="600px" url={banner.videoUrl} />
        </div>
        <ul className={articlesC} id="article">
          { articles.map((item, i) => 
            <li key={i} className={articleItem}>
              <Link to={item.linkTo}>
                <Heading Tag="h2" size="SM" bold marginBottom="Xxs">{item.title}</Heading>
                <span className={articleAuthor}>撰文 {item.author}</span>
              </Link>
            </li>
          )}
        </ul>
      </Wrapper>
      <Wrapper size="L" className={wrapperC}>
        <ul className={gallery}>
          { pictures.map((item, i) =>
            <li key={i}>
              <img src={item.publicURL} alt="atomatic" />
            </li>
          ) }
        </ul>
      </Wrapper>
      <footer className={footerC}>
        <Wrapper size="L">
          <div>
            <P size="S">主辦單位：台灣音樂人的原子交換鏈（Atomic Swap Electronic Music）</P>
            <P size="S">指導及贊助單位：國家文化藝術基金會</P>
          </div>
          <div>
            <img src="https://litzi-mei-com-files.s3.ap-northeast-1.amazonaws.com/footer-logo-1.png" alt="atomatic swap electronic music" />
          </div>
          <div>
            <img src="https://litzi-mei-com-files.s3.ap-northeast-1.amazonaws.com/footer-logo-2.png" alt="國藝會" />
          </div>
        </Wrapper>
      </footer>
    </div>
  );
};

IndexTpl.propTypes = {
  data: PropTypes.object,
};

export default IndexTpl;
